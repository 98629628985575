<template>
  <div class="login-page">
    <div class="bg-img" :style="style"></div>
    <div class="login-container">
      <el-form ref="loginFormRef" :model="state.form" :rules="rules" label-width="0">
        <el-form-item prop="username" label="">
          <el-input v-model.trim="state.form.username" @keyup.enter="submitForm" placeholder="用户名">
            <template #prepend>
              <i class="el-icon-user"></i>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password" label="">
          <el-input v-model="state.form.password" @keyup.enter="submitForm" type="password" placeholder="密码">
            <template #prepend>
              <i class="el-icon-lock"></i>
            </template>
          </el-input>
        </el-form-item>
        <div class="sumbit-btn">
          <el-button type="primary" :loading="state.loading" @click="submitForm">
            {{ state.loading ? '登录中...' : '登录' }}
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from 'vue'
import { loginByPasswd } from '@/apis'
import { useRoute, useRouter } from 'vue-router'
import { useUser } from '@/hooks'
// import { useLoginStatus } from '@/hooks/sys'
// import weixin from '@/common/weixin/weixin'
import { useStore } from 'vuex'
import { M_TOKEN } from '@/store/types'
import { logout } from '@/apis'
const BASE_URL = process.env.BASE_URL
const style = {
  backgroundImage: `url('${BASE_URL}images/login/bg-img.jpg')`
}
export default defineComponent({
  name: 'login',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const loginFormRef = ref(null)
    const { setUser } = useUser()
    // const systemSettings = useSystemSetting()

    const rules = {
      username: [{ required: true, message: '请您输入登录账户', trigger: 'blur' }],
      password: [{ required: true, message: '请您输入登录密码', trigger: 'blur' }]
    }
    const redirectUrl = () => {
      const redirect = route.query.redirect || ''
      if (redirect) {
        router.replace(decodeURIComponent(redirect))
      } else {
        router.replace('/home')
      }
    }

    const state = reactive({
      form: {
        username: '',
        password: ''
      },
      loading: false
    })
    if (process.env.NODE_ENV !== 'production') {
      // state.form.username = '162866014180910000_mr.chen'
      state.form.username = ''
      state.form.password = 'kehutong@2020'
    }
    const submitForm = () => {
      loginFormRef.value.validate(async valid => {
        if (valid) {
          try {
            await logout()
            state.loading = true
            const result = await loginByPasswd(state.form)
            console.log('result: ', result)
            if (result.code === 200) {
              setUser(result.data)
              store.commit(M_TOKEN, result.data.sessionId)
              localStorage.setItem('cropPattern', result.data.cropPattern)
              redirectUrl()
            } else {
              sessionStorage.clear()
              localStorage.clear()
            }
            state.loading = false
          } catch (error) {
            console.log('error: ', error)
            state.loading = false
          }
        } else {
          state.loading = false
          return false
        }
      })
    }

    return { state, rules, loginFormRef, submitForm, style }
  }
})
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
